import { Icons } from "@/components/icons";
import Section from "@/components/section";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import Link from "next/link";

export default function CtaSection() {
  return (
    <Section
      subtitle=<>
      Get started today and experience <br /> the future of Geo-data Intelligence
      </>
      className="bg-heroColor bg-opacity-15 py-6"
    >
      <div className="flex flex-col w-full sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4 pt-4">
      <Link
          href="/api/auth/login"
          className={cn(
            buttonVariants({ variant: "default" }),
            "w-full sm:w-auto flex gap-2"
          )}
        >
          <Icons.getStarted className="w-auto h-[30px]" />
          Get started for free
        </Link>
      </div>
    </Section>
  );
}
